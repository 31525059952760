import React, { useEffect, useState } from "react";
import { sendGetRequest } from "../services/common";
import { URLS } from "../services/urls";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SelectCountryCode = (props) => {
  const [countriesList, setCountriesList] = useState([]);
  const { countryCode = "", handleCountryCodeChange, variant, ...restprops } = props;

  const getCountriesList = async () => {
    try {
      const response = await sendGetRequest({ url: URLS.COUNTRIES_LIST });
      if (response?.data?.data?.results) {
        setCountriesList(response.data.data.results);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <>
        <FormControl fullWidth variant={variant} label="Country Code">
          <InputLabel id="demo-simple-select-label">Country Code</InputLabel>
          <Select
            {...restprops}
            label="Country Code"
            value={countryCode}
            onChange={handleCountryCodeChange}
            // displayEmpty
            renderValue={(val) => val}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            <MenuItem value=" " disabled>
              Select Country Code
            </MenuItem>
            {countriesList.map((cc, id) => (
              <MenuItem value={cc.phone_code} key={id}>
                {cc.emoji} {cc.phone_code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
    </>
  );
};

export default SelectCountryCode;
