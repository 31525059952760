import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Divider,
  Box,
} from "@mui/material";
import SelectedNavIcon from "../../../assets/images/selected-nav.svg";
import { Navs } from "./utils";
import ProfileLogoutTab from "./ProfileLogoutTab";
import ywbLogo from "../../../assets/images/ywb-logo.svg";

const WebNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user_type = localStorage.getItem("user_type");

  const handleNavClick = (path) => {
    navigate(path);
  };

  const renderItms = (item, index) => {
    const defaultView = () => (
      <ListItemButton
        key={index}
        selected={pathname === item.value}
        className="nav-item"
        onClick={() => handleNavClick(item.value)}
      >
        <ListItemIcon>
          <img src={item.img} alt={item.name} />
        </ListItemIcon>
        <ListItemText primary={item.name} />
        {pathname === item.value && (
          <img src={SelectedNavIcon} alt="selected" />
        )}
      </ListItemButton>
    );

    switch (item.name) {
      case "Reports":
        return user_type === "2" ? defaultView() : null;
      case "Payout":
        return user_type === "2" ? defaultView() : null;
      case "Logout":
        return <ProfileLogoutTab key={index} />;
      case "Company Profile":
        return user_type === "2" ? defaultView() : null;
      case "My Profile":
        return user_type === "1" ? defaultView() : null;
      case "KYC Documentations":
        return user_type === "2" ? defaultView() : null;
      default:
        return defaultView();
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: "22%"},
      }}
      open
      className="sideBar"
    >
      <Toolbar className="drawer-header" sx={{ justifyContent: "center" }}>
        <Box mt={5} mb={5}>
          <img src={ywbLogo} alt="ywb Logo" className="cursor-pointer" onClick={()=>navigate('/')} />
        </Box>
      </Toolbar>
      <List sx={{margin:"0 0.5rem 0 0.5rem"}} className="list-nav_mob">
        {Navs.map((navGroup, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {navGroup.map((item, itemIndex) => renderItms(item, itemIndex))}
            {groupIndex !== Navs.length - 1 && (
              <Divider
                sx={{
                  borderBottomWidth: 1,
                  background: "white",
                  margin: "1rem 0.5rem",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default WebNav;
